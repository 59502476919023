// import node modules
import React, { Component } from "react";
import ImageCard from "../../../components/ImageCard/ImageCard.component";
import CollapseComponent from "../../../components/Collapse/Collapse.component";
import { Container, Row } from "reactstrap";
import SEO from "../../../components/seo";

//import images
import PgroupBackground from "../../../assets/images/san-francisco-golden-gate-bridge.png";

// import constant files
import {
  SS_HEADER_TEXT,
  SP_HEADER_TEXT,
  WC_HEADER_TEXT
} from "../../../constants/views/Products/ProductGroups.constant";

class ProductGroups extends Component {
  
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {

    const sliced_salami = this.props.products.sliced_salami;
    const sliced_pepperoni = this.props.products.sliced_pepperoni;
    const whole_chubs = this.props.products.whole_chubs;
    let first_counter_limit = 2;
    let chubs_first_counter_limit = 0;
    let items_to_show = 3;
    let chub_items_to_show = 1;

    if (this.state.width < 768) {
      first_counter_limit = 1;
      items_to_show = 2;
    }
    let second_counter_limit = first_counter_limit + items_to_show;
    let chubs_second_counter_limit = chubs_first_counter_limit + chub_items_to_show;

    return (
      <React.Fragment>
        <SEO title={this.props.productMetaTitle} description={this.props.productMetaDescription} />
        <section
          style={{ backgroundImage: `url(${PgroupBackground})` }}
          className="gagl-pgroup-wrapper gagl-nav-top-space"
          role="img" aria-label="san francisco golden gate bridge"
        >
          <h1 className="gagl-rgroup-header-text gagl-pgroup-top-header-text">{SS_HEADER_TEXT}</h1>
          <Container className="gagl-pgroup-container">
            <Row>
              {
                sliced_salami.map((value, index) => {
                  if (index <= first_counter_limit)
                    return <ImageCard
                      itemTextClass="gagl-rgroup-item-text"
                      imgClass="gagl-pgroup-img-small"
                      key={value.slug}
                      imgSrc={value.image}
                      alt={value.alt_text}
                      itemText={value.name}
                      slug={value.slug}
                      type="our-products"
                      item=" "></ImageCard>
                  else
                    return '';
                })
              }
            </Row>
            <CollapseComponent
              products={this.props.products}
              class="gagl-pr-collapse-block"
              type="our-products"
              group="sliced_salami"
              length={sliced_salami.length}
              itemTextClass="gagl-rgroup-item-text"
              imgClass="gagl-pgroup-img-small"
              btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
              start={first_counter_limit}
              end={second_counter_limit}
              item=" "></CollapseComponent>
          </Container>

          <h2 className="gagl-rgroup-header-text ">{SP_HEADER_TEXT}</h2>
          <Container className="gagl-pgroup-container">
            <Row>
              {
                sliced_pepperoni.map((value, index) => {
                  if (index <= first_counter_limit)
                    return <ImageCard
                      itemTextClass="gagl-rgroup-item-text"
                      imgClass="gagl-pgroup-img-small"
                      key={value.slug}
                      imgSrc={value.image}
                      alt={value.alt_text}
                      itemText={value.name}
                      slug={value.slug}
                      type="our-products"
                      item=" "></ImageCard>
                  else
                    return '';
                })
              }
            </Row>
            <CollapseComponent
              products={this.props.products}
              class="gagl-pr-collapse-block" type="our-products"
              group="sliced_pepperoni"
              length={sliced_pepperoni.length}
              itemTextClass="gagl-rgroup-item-text"
              imgClass="gagl-pgroup-img-small"
              btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red"
              start={first_counter_limit}
              end={second_counter_limit}
              item=" "></CollapseComponent>
          </Container>

          <h2 className="gagl-rgroup-header-text ">{WC_HEADER_TEXT}</h2>
          <Container className="gagl-pgroup-container">
            <Row>
              {
                whole_chubs.map((value, index) => {
                  if (index <= chubs_first_counter_limit)
                    return <ImageCard
                      itemTextClass="gagl-rgroup-item-text"
                      imgClass="gagl-pgroup-img-large"
                      key={value.slug}
                      imgSrc={value.image}
                      alt={value.alt_text}
                      itemText={value.name}
                      slug={value.slug}
                      type="our-products"
                      item="chubs"></ImageCard>
                  else
                    return '';
                })
              }
            </Row>
            <CollapseComponent
              products={this.props.products}
              class="gagl-pr-collapse-block" type="our-products"
              group="whole_chubs"
              length={whole_chubs.length}
              itemTextClass="gagl-rgroup-item-text"
              imgClass="gagl-pgroup-img-large"
              btnClass="gagl-rgroup-btn gagl-btn gagl-btn-red last-btn"
              start={chubs_first_counter_limit}
              end={chubs_second_counter_limit}
              item="chubs"></CollapseComponent>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductGroups;
