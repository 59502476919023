import React from "react";

export const PRODUCT_DETAILS_DATA = {
  "sliced-italian-dry-salame": {
    productName: "sliced italian dry salame",
    metaTitle: "Sliced Italian Dry Salame | Our Products | Galileo® Salame",
    metaDescription:
      "We make our Italian dry salame the old-fashioned way: with high quality, lean pork, exceptional spices and lots of care and attention. View our sliced salame products.",
    availableIn: "7-oz, 15.2-oz, 32-oz",
    WTBLink:
      "/where-to-buy/?PROD=00044500073287,00044500331479,00044500331486&MM=panel2",
    productDescription: {
      text:
        "Our classic deli-style salame: masterfully cured and wonderfully spiced.",
      list: [
        "Deli thin sliced",
        "Unique herb and spice blend",
        "Incredibly tangy",
        "100% high-quality pork",
      ],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Nonfat Milk, 
          Dextrose, Salt, Contains 2% Or 
          Less Of: Natural
          Spices, Wine, Vitamin C (Ascorbic Acid),
          Granulated Garlic, Lactic
          Acid Starter Culture, 
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 7",
      servingSize: "5 slices (28g)",
      calories: "100",
      totalFat: "8",
      totalFatPercent: "12",
      satFat: "2.5",
      satFatPercent: "13",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "20",
      cholesterolPercent: "7",
      sodium: "490",
      sodiumPercent: "20",
      carbohydrate: "1",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "1",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "6",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "2",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "salame-grilled-cheese",
      "cheesy-salame-pepperoni-pasta-bake",
      "salame-pepperoni-charcuterie-plate",
      "salame-chips-honey-mustard-dip",
    ],
  },
  "sliced-light-italian-dry-salame": {
    productName: "sliced light italian dry salame",
    metaTitle: "Sliced Light Italian Dry Salame | Our Products | Galileo® Salame",
    metaDescription:
      "Our light Italian dry salame is full of flavor, made with lean high-quality pork, and only 70 calories per serving. View our sliced light Italian dry salame products.",
    availableIn: "7-oz",
    WTBLink: "/where-to-buy/?PROD=00044500073263&MM=panel2",
    productDescription: {
      text:
        "Full of flavor and half the fat, our Light Italian Dry Salame is only 70 calories per serving.",
      list: [
        "Great taste with 55% less fat",
        "Deli thin sliced",
        "100% high-quality pork",
      ],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Nonfat Milk, Dextrose, Salt, 
          Contains 2% Or 
          Less Of: Natural Spices, Wine,
          Vitamin C (Ascorbic Acid), 
          Granulated Garlic, Lactic Acid Starter Culture,
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 7",
      servingSize: "6 slices (28g)",
      calories: "80",
      totalFat: "4.5",
      totalFatPercent: "7",
      satFat: "1.5",
      satFatPercent: "8",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "20",
      cholesterolPercent: "7",
      sodium: "520",
      sodiumPercent: "22",
      carbohydrate: "1",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "1",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "8",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "2",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "italian-pinwheels",
      "antipasto-salame-sandwich",
      "cheesy-salame-pepperoni-pasta-bake",
      "antipasto-skewers",
    ],
  },
  "sliced-all-natural-italian-dry-salame": {
    productName: "sliced all natural italian dry salame",
    metaTitle: "Sliced All Natural Italian Dry Salame | Our Products | Galileo® Salame",
    metaDescription:
      "Our signature recipe full of flavor without any of the preservatives. View our all-natural Italian dry salame products.",
    availableIn: "6-oz",
    WTBLink: "/where-to-buy/?PROD=00044500073294&MM=panel2",
    productDescription: {
      text:
        "Old-school method without the preservatives, our uncured All Natural Italian Dry Salame is made with our unique blend of herbs and spices.",
      list: ["No nitrites or nitrates added", "No added preservatives or artificial ingredients", "100% high-quality pork"],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Dextrose, Salt, 
          Contains 2% or Less: 
          Sugar, Spices, Celery Powder,
          Sea Salt, Natural Flavor, 
          Granulated Garlic, 
          Lactic Acid Starter Culture.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 6",
      servingSize: "5 slices (28g)",
      calories: "100",
      totalFat: "8",
      totalFatPercent: "12",
      satFat: "2.5",
      satFatPercent: "13",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "25",
      cholesterolPercent: "8",
      sodium: "390",
      sodiumPercent: "16",
      carbohydrate: "1",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "0",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "6",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "0",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "italian-sandwich",
      "salame-calzone",
      "pizza-monkey-bread",
      "hot-pizza-dip-garlic-crostini",
    ],
  },
  "italian-dry-salame-medallions": {
    productName: "italian dry salame medallions",
    metaTitle: "Italian Dry Salame Medallions | Our Products | Galileo® Salame",
    metaDescription:
      "We make our Italian dry salame the old-fashioned way: with high quality, lean pork, exceptional spices and lots of care and attention. View our salame snack medallions products.",
    availableIn: "2.9-oz",
    WTBLink: "/where-to-buy/?PROD=00044500073577&MM=panel2",
    productDescription: {
      text:
        "Delicious and tangy Italian Dry Salame Medallions bursting with flavor. Perfect on a cracker, in a pasta salad, or for snacking on anytime.",
      list: [
        "Thick cut and bite sized",
        "Great for snacking",
        "100% high-quality pork",
      ],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Nonfat Dry Milk, 
          Dextrose, Salt, Contains 2% Or 
          Less: Natural Spices, Wine, 
          Vitamin C (Ascorbic Acid), 
          Granulated Garlic, 
          Lactic Acid Starter Culture,
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 2",
      servingSize: "1/2 Package (41g)",
      calories: "150",
      totalFat: "11",
      totalFatPercent: "17",
      satFat: "4",
      satFatPercent: "20",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "35",
      cholesterolPercent: "12",
      sodium: "790",
      sodiumPercent: "33",
      carbohydrate: "3",
      carbohydratePercent: "1",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "3",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "10",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "4",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "salame-pepperoni-charcuterie-plate",
      "italian-bean-salad",
      "italian-salame-bean-soup",
      "pepperoni-flatbread-pizza",
    ],
  },
  "sliced-pepperoni": {
    productName: "sliced pepperoni",
    metaTitle: "Sliced Pepperoni | Our Products | Galileo® Salame",
    metaDescription:
      "Our rich and zesty pepperoni has the authentic Italian flavor you—and your pizza—will love. View our sliced pepperoni products.",
    availableIn: "7-oz",
    WTBLink: "/where-to-buy/?PROD=00044500073256&MM=panel2",
    productDescription: {
      text:
        "Our authentic Italian sliced pepperoni is made with a unique blend of herbs and spices giving it delicious flavor perfect for pizzas, pastas and sandwiches.",
      list: [
        "Robust flavor profile",
        "100% high-quality pork and beef",
        "0 grams of trans fat per serving",
      ],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Beef, Salt, 
          Contains 2% Or 
          Less Of: Corn Syrup, Natural Spices, 
          Natural Flavorings, 
          Oleoresin Of Paprika, Rosemary Extract, 
          Lactic Acid Starter Culture,
          Garlic Powder, 
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 7",
      servingSize: "9 slices (28g)",
      calories: "120",
      totalFat: "10",
      totalFatPercent: "15",
      satFat: "3.5",
      satFatPercent: "18",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "25",
      cholesterolPercent: "8",
      sodium: "460",
      sodiumPercent: "19",
      carbohydrate: "0",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "0",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "6",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "0",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "pepperoni-flatbread-pizza",
      "hot-pizza-dip-garlic-crostini",
      "pepperoni-cheese-bread",
      "meat-lovers-calzone",
    ],
  },
  "sliced-all-natural-pepperoni": {
    productName: "sliced all natural pepperoni",
    metaTitle: "Sliced All Natural Pepperoni | Our Products | Galileo® Salame",
    metaDescription:
      "Our signature rich and zesty pepperoni without any of the preservatives. View our sliced all-natural pepperoni products.",
    availableIn: "6-oz",
    WTBLink: "/where-to-buy/?PROD=00044500077056&MM=panel2",
    productDescription: {
      text:
        "Old-school method without the preservatives, our uncured All Natural Pepperoni is made with our unique blend of herbs and spice for added zest.",
      list: ["No nitrites or nitrates added", "No added preservatives or artificial ingredients", "100% high-quality pork and beef"],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Beef, Salt, 
          Contains 2% Or Less: Dextrose, Natural Spices,
          Celery Powder, 
          Extractives Of Paprika, Natural Flavor, Sea Salt, 
          Garlic Powder, Lactic Acid Starter Culture.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 6",
      servingSize: "9 slices (28g)",
      calories: "120",
      totalFat: "10",
      totalFatPercent: "15",
      satFat: "3.5",
      satFatPercent: "18",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "35",
      cholesterolPercent: "12",
      sodium: "430",
      sodiumPercent: "18",
      carbohydrate: "1",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "0",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "6",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "0",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "pepperoni-hoagie",
      "italian-pinwheels",
      "cheesy-salame-pepperoni-pasta-bake",
      "pepperoni-rolls",
    ],
  },
  "pepperoni-medallions": {
    productName: "pepperoni medallions",
    metaTitle: "Pepperoni Medallions | Our Products | Galileo® Salame",
    metaDescription:
      "Our rich and zesty pepperoni has the authentic Italian flavor you—and your pizza—will love. View our pepperoni medallion products.",
    availableIn: "2.9-oz",
    WTBLink: "/where-to-buy/?PROD=00044500073584&MM=panel2",
    productDescription: {
      text:
        "Delicious, thick-cut pepperoni medallions bursting with flavor. Perfect for topping homemade pizzas, sprinkled atop a salad or for snacking on anytime.",
      list: ["Thick cut and bite sized", "Great for on-the-go snacking", "100% high-quality pork and beef"],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Beef, Salt, 
          Contain 2% Or Less Of: Corn Syrup,
          Natural Spices, 
          Natural Flavorings, Oleoresin Of Paprika, 
          Rosemary Extract, 
          Lactic Acid Starter Culture,
          Garlic Powder, 
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 2",
      servingSize: "1/2 Package (41g)",
      calories: "170",
      totalFat: "14",
      totalFatPercent: "22",
      satFat: "5",
      satFatPercent: "25",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "40",
      cholesterolPercent: "13",
      sodium: "710",
      sodiumPercent: "30",
      carbohydrate: "0",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "0",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "9",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "6",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "0",
      iron: "",
      ironPercent: "4",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "pepperoni-pizza",
      "salame-pepperoni-charcuterie-plate",
      "pepperoni-cheese-bread",
      "italian-bean-salad",
    ],
  },
  "italian-dry-salame-chubs": {
    productName: "italian dry salame chub",
    metaTitle: "Italian Dry Salame Chub | Our Products | Galileo® Salame",
    metaDescription:
      "Seasoned cure masters tend to our Italian dry salame chubs until they’re perfectly aged—and perfectly delicious. View our salame chubs.",
    availableIn: "8-oz",
    WTBLink: "/where-to-buy/?PROD=00044500331462&MM=panel2",
    productDescription: {
      text:
        "Thick-cut, thin-sliced and everything in between, our Italian Dry Salame Chubs are a delicious addition to your favorite recipes, party platters or simply as a snack.",
      list: [
        "Slow cured",
        "Seasoned with traditional Italian spices",
        "100% high-quality pork",
      ],
    },
    productIngredient: {
      text: (
        <p className="gagl-iic--para">
          Pork, Nonfat Milk, 
          Dextrose, Salt, Contains 2% 
          Or Less: Natural Spices, 
          Wine, Vitamin C (Ascorbic Acid),
          Granulated Garlic, 
          Lactic Acid Starter Culture, 
          Sodium Nitrite, Sodium Nitrate.
        </p>
      ),
    },
    productNutritionPanel: {
      servings: "About 8",
      servingSize: "1 oz (28g)",
      calories: "110",
      totalFat: "9",
      totalFatPercent: "14",
      satFat: "3",
      satFatPercent: "15",
      transFat: "0",
      polyUnsatFat: "N/A",
      monoUnsatFat: "N/A",
      cholesterol: "25",
      cholesterolPercent: "8",
      sodium: "500",
      sodiumPercent: "21",
      carbohydrate: "1",
      carbohydratePercent: "0",
      dietaryFiber: "0",
      dietaryFiberPercent: "0",
      totalSugars: "1",
      addedSugars: "0",
      addedSugarsPercent: "0",
      protein: "6",
      vitaminD: "",
      vitaminDPercent: "",
      vitaminA: "",
      vitaminAPercent: "0",
      vitaminC: "",
      vitaminCPercent: "0",
      calcium: "",
      calciumPercent: "2",
      iron: "",
      ironPercent: "2",
      potassium: "",
      potassiumPercent: "0",
    },
    more_recipe: [
      "salame-pepperoni-charcuterie-plate",
      "italian-salame-bean-soup",
      "italian-bean-salad",
      "salame-pasta-salad",
    ],
  },
};
